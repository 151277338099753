<section role="region" aria-label="Capbility model"
    class="display-flex flex-column padding-top-8 padding-x-2 tablet:padding-x-4 gap-4 width-full flex-align-center"
    id="capability-model">
    <div class="display-flex usa-prose tablet:padding-x-4 flex-column maxw-desktop-lg width-full" id="widget">
        <h1><b class="text-primary-light">Capability </b> <span class="text-normal"> Model</span></h1>
        <p><em>Interact with any section to learn more.</em></p>
    </div>
    <div class="position-relative flex-justify-center display-flex width-full">
        <div class="capability-model desktop width-full">
            <div class="cell dp">
                <span>
                    <strong>Data<br>Providers</strong>
                </span>
            </div>
            <div class="cell da">
                <span>
                    <strong>Data<br>Aggregators</strong>
                </span>
            </div>
            <div class="cell dc">
                <span>
                    <strong>Data<br>Consumers</strong>
                </span>
            </div>
            <div class="cell header provision">
                <a [href]="baseSlug + 'provision'" class="display-flex flex-justify flex-align-center">
                    Provision
                    <i class="ti ti-chevron-right font-md"></i>
                </a>
            </div>
            <div class="cell header collection">
                <a [href]="baseSlug + 'collection'" class="display-flex flex-justify flex-align-center">
                    Collection
                    <i class="ti ti-chevron-right font-md"></i>
                </a>
            </div>
            <div class="cell header management">
                <a [href]="baseSlug + 'management'" class="display-flex flex-justify flex-align-center">
                    Management
                    <i class="ti ti-chevron-right font-md"></i>
                </a>
            </div>
            <div class="cell header use">
                <a [href]="baseSlug + 'use'" class="display-flex flex-justify flex-align-center">
                    Use
                    <i class="ti ti-chevron-right font-md"></i>
                </a>
            </div>
            <div class="cell header leverage">
                <a [href]="baseSlug + 'leverage'" class="display-flex flex-justify flex-align-center">
                    Leverage
                    <i class="ti ti-chevron-right font-md"></i>
                </a>
            </div>
            <div class="cell pipeline-header vertical-header">
                <span>
                    Data Pipeline
                </span>
            </div>
            <div class="cell pipeline-provision top container">
                <div class="collection width-full">
                    <a [href]="baseSlug + 'provide'">Provide</a>
                    <a [href]="baseSlug + 'data_quality___improve'">Data Quality - Improve</a>
                    <a [href]="baseSlug + 'data_quality___certify'">Data Quality - Certify</a>
                </div>
            </div>
            <div class="cell pipeline-collection top container">
                Collect
                <div class="collection width-full">
                    <a [href]="baseSlug + 'move'">Move</a>
                    <a [href]="baseSlug + 'ingest'">Ingest</a>
                    <a [href]="baseSlug + 'data_quality___review'">Data Quality - Review</a>
                    <a [href]="baseSlug + 'data_quality___validate'">Data Quality - Validate</a>
                </div>
            </div>
            <div class="cell pipeline-management top container">
                <div class="display-flex width-full flex-row gap-105">
                    <div class="display-flex width-full flex-column gap-105">
                        Steward
                        <div class="collection">
                            <a [href]="baseSlug + 'manage_metadata'">Manage Metadata</a>
                            <a [href]="baseSlug + 'document'">Document</a>
                            <a [href]="baseSlug + 'tag'">Tag</a>
                            <a [href]="baseSlug + 'classify'">Classify</a>
                            <a [href]="baseSlug + 'map_lineage'">Map Lineage</a>
                        </div>
                        Master
                        <div class="collection">
                            <a [href]="baseSlug + 'resolve'">Resolve</a>
                            <a [href]="baseSlug + 'maintain'">Maintain</a>
                        </div>
                    </div>
                    <div class="display-flex width-full flex-column gap-105">
                        Integrate
                        <div class="collection">
                            <a [href]="baseSlug + 'combine'">Combine</a>
                            <a [href]="baseSlug + 'link'">Link</a>
                        </div>
                        Retain
                        <div class="collection">
                            <a [href]="baseSlug + 'archive'">Archive</a>
                            <a [href]="baseSlug + 'audit'">Audit</a>
                            <a [href]="baseSlug + 'recover'">Recover</a>
                        </div>
                        Secure
                        <div class="collection">
                            <a [href]="baseSlug + 'suppress'">Suppress</a>
                            <a [href]="baseSlug + 'pseudonymize'">Pseudonymize</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cell pipeline-use top container">
                <div class="display-flex width-full flex-row gap-105">
                    <div class="display-flex width-full flex-column gap-105">
                        Prepare
                        <div class="collection">
                            <a [href]="baseSlug + 'curate'">Curate</a>
                            <a [href]="baseSlug + 'format'">Format</a>
                            <a [href]="baseSlug + 'deliver_analysis'">Deliver Analysis</a>
                            <a [href]="baseSlug + 'deliver_data'">Deliver Data</a>
                            <a [href]="baseSlug + 'notify'">Notify</a>
                            <a [href]="baseSlug + 'collaborate'">Collaborate</a>
                        </div>
                    </div>
                    <div class="display-flex width-full flex-column gap-105">
                        Enhance
                        <div class="collection">
                            <a [href]="baseSlug + 'augment'">Augment</a>
                            <a [href]="baseSlug + 'aggregate'">Aggregate</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cell pipeline-leverage top last container">
                Consume
                <div class="collection width-full">
                    <a [href]="baseSlug + 'view'">View</a>
                    <a [href]="baseSlug + 'interact_6a7baf4'">Interact</a>
                    <a [href]="baseSlug + 'download'">Download</a>
                    <a [href]="baseSlug + 'evaluate'">Evaluate</a>
                    <a [href]="baseSlug + 'search'">Search</a>
                </div>
                Analyze
                <div class="collection width-full">
                    <a [href]="baseSlug + 'interpret'">Interpret</a>
                    <a [href]="baseSlug + 'predict'">Predict</a>
                </div>
            </div>

            <div class="cell operational-header vertical-header">
                <span>
                    Operational
                </span>
            </div>
            <div class="cell operational-provision container"></div>
            <div class="cell operational-collection container">
                Enable
                <div class="collection">
                    <a [href]="baseSlug + 'automate'">Automate</a>
                    <a [href]="baseSlug + 'synthesize_data'">Synthesize Data</a>
                </div>
            </div>
            <div class="cell operational-management container">
                <div class="display-flex width-full flex-row gap-105">
                    <div class="display-flex width-full flex-column gap-105">
                        Enable
                        <div class="collection">
                            <a [href]="baseSlug + 'interact'">Interact</a>
                            <a [href]="baseSlug + 'store'">Store</a>
                        </div>
                    </div>
                    <div class="display-flex width-full flex-column gap-105">
                        Secure
                        <div class="collection">
                            <a [href]="baseSlug + 'authorize'">Authorize</a>
                            <a [href]="baseSlug + 'authenticate'">Authenticate</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cell operational-use container"></div>
            <div class="cell operational-leverage last container"></div>

            <div class="cell organizational-header vertical-header">
                <span>
                    Organizational
                </span>
            </div>
            <div class="cell organizational-provision container"></div>
            <div class="cell organizational-collection container"></div>
            <div class="cell organizational-management container">
                <div class="display-flex width-full flex-row gap-105">
                    <div class="display-flex width-full flex-column gap-105">
                        Governance
                        <div class="collection">
                            <a [href]="baseSlug + 'standardize'" class="width-full">Standardize</a>
                            <a [href]="baseSlug + 'enforce_retention_policies'" class="width-full">Enforce Retention Policies</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cell organizational-use container">
                <div class="display-flex width-full flex-row gap-105">
                    <div class="display-flex width-full flex-column gap-105">
                        Governance
                        <div class="collection">
                            <a [href]="baseSlug + 'share'" class="width-full">Share</a>
                            <a [href]="baseSlug + 'enforce_privacy_policies'" class="width-full">Enforce Privacy Policies</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cell organizational-leverage last container"></div>
        </div>
        <div class="capability-model-mobile width-full tablet:padding-x-4 maxw-tablet">
            <a [routerLink]="'/'" class="header">
                Data Pipeline
            </a>
            <div class="section-wrapper display-flex flex-column gap-2 padding-left-1"
                aria-label="Data pipeline section">
                <p class="sub-header">
                    Data Providers
                </p>
                <a [href]="baseSlug + 'provision'" class="column-header">
                    Provision
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'provide'">Provide</a>
                    <a [href]="baseSlug + 'data_quality___improve'">Data Quality - Improve</a>
                    <a [href]="baseSlug + 'data_quality___certify'">Data Quality - Certify</a>
                </div>
                <a [routerLink]="'/'" class="sub-header">
                    Data Aggregators
                </a>
                <a [href]="baseSlug + 'collection'" class="column-header">
                    Collection
                </a>
                <a [href]="baseSlug + 'collect'" class="column-sub-header">
                    Collect
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'move'">Move</a>
                    <a [href]="baseSlug + 'ingest'">Ingest</a>
                    <a [href]="baseSlug + 'data_quality___review'">Data Quality - Review</a>
                    <a [href]="baseSlug + 'data_quality___validate'">Data Quality - Validate</a>
                </div>
                <a [href]="baseSlug + 'management'" class="column-header">
                    Management
                </a>
                <a [href]="baseSlug + 'steward'" class="column-sub-header">
                    Steward
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'manage_metadata'">Manage Metadata</a>
                    <a [href]="baseSlug + 'document'">Document</a>
                    <a [href]="baseSlug + 'tag'">Tag</a>
                    <a [href]="baseSlug + 'classify'">Classify</a>
                    <a [href]="baseSlug + 'map_lineage'">Map Lineage</a>
                </div>
                <a [href]="baseSlug + 'master'" class="column-sub-header">
                    Master
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'resolve'">Resolve</a>
                    <a [href]="baseSlug + 'maintain'">Maintain</a>
                </div>
                <a [href]="baseSlug + 'integrate'" class="column-sub-header">
                    Integrate
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'combine'">Combine</a>
                    <a [href]="baseSlug + 'link'">Link</a>
                </div>
                <a [href]="baseSlug + 'retain'" class="column-sub-header">
                    Retain
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'archive'">Archive</a>
                    <a [href]="baseSlug + 'audit'">Audit</a>
                    <a [href]="baseSlug + 'recover'">Recover</a>
                </div>
                <a [href]="baseSlug + 'secure'" class="column-sub-header">
                    Secure
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'suppress'">Suppress</a>
                    <a [href]="baseSlug + 'pseudonymize'">Pseudonymize</a>
                </div>
                <a [href]="baseSlug + 'use'" class="column-header">
                    Use
                </a>
                <a [href]="baseSlug + 'prepare'" class="column-sub-header">
                    Prepare
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'curate'">Curate</a>
                    <a [href]="baseSlug + 'format'">Format</a>
                    <a [href]="baseSlug + 'deliver_analysis'">Deliver Analysis</a>
                    <a [href]="baseSlug + 'deliver_data'">Deliver Data</a>
                    <a [href]="baseSlug + 'notify'">Notify</a>
                    <a [href]="baseSlug + 'collaborate'">Collaborate</a>
                </div>
                <a [href]="baseSlug + 'enhance'" class="column-sub-header">
                    Enhance
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'augment'">Augment</a>
                    <a [href]="baseSlug + 'aggregate'">Aggregate</a>
                </div>
                <a [routerLink]="'/'" class="sub-header">
                    Data Consumers
                </a>
                <a [href]="baseSlug + 'leverage'" class="column-header">
                    Leverage
                </a>
                <a [href]="baseSlug + 'consume'" class="column-sub-header">
                    Consume
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'view'">View</a>
                    <a [href]="baseSlug + 'interact_6a7baf4'">Interact</a>
                    <a [href]="baseSlug + 'download'">Download</a>
                    <a [href]="baseSlug + 'evaluate'">Evaluate</a>
                    <a [href]="baseSlug + 'search'">Search</a>
                </div>
                <a [href]="baseSlug + 'analyze'" class="column-sub-header">
                    Analyze
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'interpret'">Interpret</a>
                    <a [href]="baseSlug + 'predict'">Predict</a>
                </div>
            </div>


            <a [routerLink]="'/'" class="header">
                Operational
            </a>
            <div class="section-wrapper display-flex flex-column gap-2 padding-left-1"
                aria-label="Operational section">
                <a [routerLink]="'/'" class="sub-header">
                    Data Aggregators
                </a>
                <a [href]="baseSlug + 'collection'" class="column-header">
                    Collection
                </a>
                <a [href]="baseSlug + 'enable'" class="column-sub-header">
                    Enable
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'automate'">Automate</a>
                    <a [href]="baseSlug + 'synthesize_data'">Synthesize Data</a>
                </div>
                <a [href]="baseSlug + 'management'" class="column-header">
                    Management
                </a>
                <a [href]="baseSlug + 'enable'" class="column-sub-header">
                    Enable
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'interact'">Interact</a>
                    <a [href]="baseSlug + 'store'">Store</a>
                </div>
                <a [href]="baseSlug + 'secure'" class="column-sub-header">
                    Secure
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'authorize'">Authorize</a>
                    <a [href]="baseSlug + 'authenticate'">Authenticate</a>
                </div>
            </div>
            <a [routerLink]="'/'" class="header">
                Organizational
            </a>
            <div class="section-wrapper display-flex flex-column gap-2 padding-left-1"
                aria-label="Organizational section">
                <a [routerLink]="'/'" class="sub-header">
                    Data Aggregators
                </a>
                <a [href]="baseSlug + 'management'" class="column-header">
                    Management
                </a>
                <a [href]="baseSlug + 'governance'" class="column-sub-header">
                    Governance
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'standardize'">Standardize</a>
                    <a [href]="baseSlug + 'enforce_retention_policies'">Enforce Retention Policies</a>
                </div>
                <a [href]="baseSlug + 'use'" class="column-header">
                    Use
                </a>
                <a [href]="baseSlug + 'governance_d73fa29'" class="column-sub-header">
                    Governance
                </a>
                <div class="collection width-full">
                    <a [href]="baseSlug + 'share'">Share</a>
                    <a [href]="baseSlug + 'enforce_privacy_policies'">Enforce Privacy Policies</a>
                </div>
            </div>
        </div>
    </div>

</section>